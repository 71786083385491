<template>
  <div class="vehicle-online-rate" ref="vehicleOnlineRate">
    <div class="function-buttons" ref="functionButtons" v-show="false">
      <el-button type="primary" size="small"></el-button>
    </div>
    <div class="form-area" ref="form">
      <analysisForm @getFormVal="onSubmit">
        <el-button
          @click="getXLS"
          type="primary"
          size="small"
          :disabled="tableData.length === 0"
          >导出</el-button
        >
      </analysisForm>
    </div>
    <el-table id="outputTable" :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属车队"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="cph" label="车牌号"></el-table-column>
      <el-table-column
        prop="totalOnlineTimeStr"
        label="在线时间"
      ></el-table-column>
      <el-table-column
        prop="totalOfflineTimeStr"
        label="离线时间"
      ></el-table-column>
      <el-table-column
        prop="totalTimeStr"
        label="选择周期总时长"
      ></el-table-column>
      <el-table-column
        prop="onlineRate"
        label="在线率"
        :formatter="formatRate"
      ></el-table-column>
      <el-table-column
        prop="totalOperateTime"
        label="营运时长"
        :formatter="formatTime"
      ></el-table-column>
      <el-table-column
        prop="operateRate"
        label="营运比例"
        :formatter="formatRate"
      ></el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import analysisForm from '@/components/analysisForm/analysisForm.vue'
import { statisticsVehicleOnlineList } from '@/api/lib/taxi-api.js'
import { YHTools } from '@/assets/js/Tools.js'
import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import { getCurent } from '@/common/utils/index'

export default {
  name: 'vehicleOnlineRate',
  components: {
    analysisForm
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        companyId:null,
        vehicleNos: [],
        beginTime: null,
        endTime: null,
        currentPage: 1,
        pageSize: 10
      },
      columns: ['companyName', 'cph', 'totalOnlineTime', 'totalOfflineTime', 'totalTime', 'onlineRate', 'totalOperateTime', 'operateRate'],
      total: 0
    }
  },
  methods: {
    /** 导出 */
    getXLS () {
      // getXLS('#outputTable', '车辆在线率统计')
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      delete this.exportForm.date;
      let data = {
        baseUrl: "report",
        fileName: `车辆在线率统计-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: "/online/vehicleOnlineExport",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.vehicleOnlineRate.clientHeight
      let buttonsHeight = this.$refs.functionButtons.clientHeight
      let formHeight = this.$refs.form.clientHeight
      let paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight = wholeHeight - 16 - buttonsHeight - 16 - formHeight - paginationHeight - 10
    },
    // 提交
    onSubmit (value) {
      this.form.companyId =value.companyId ||null
      this.form.vehicleNos = value.vehicleNos
      this.form.beginTime = value.beginTime
      this.form.endTime = value.endTime
      this.getDataList()
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getDataList()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.getDataList()
    },
    // 获取列表
    getDataList () {
      statisticsVehicleOnlineList(this.form).then(res => {
        this.exportForm = { ...this.form }
        if (res.code === 1000) {
          if (res.data.list.length > 0) {
            // console.log(1111,res)
            this.total = res.data.total
            this.tableData = res.data.list;
            // const sums = this.getSummaries(res.data.list)
            // this.tableData = [sums, ...res.data.list]
          } else {
            this.tableData = []
          }
        }
      })
    },
    /** 合计 */
    getSummaries (data) {
      let res = {}
      this.columns.forEach((column, index) => {
        if (index === 0) {
          res[column] = '合计'
          return
        }
        if (index === 1) {
          res[column] = '-'
          return
        }
        if (index === 4) {
          res[column] = data[0].totalTime
          return
        }
        if (index === 5) {
          res[column] = res.totalOnlineTime / (res.totalTime * data.length)
          return
        }
        if (index === 7) {
          res[column] = res.totalOperateTime / (res.totalTime * data.length)
          return
        }

        const values = Array.from(data, item => item[column])
        if (!values.every(value => isNaN(value))) {
          res[column] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
        } else {
          res[column] = ''
        }
      })

      return res
    },
    /** 数据% */
    formatRate (row, column, cellValue) {
      return `${parseInt(cellValue * 100) < parseFloat(cellValue * 100) ? (cellValue * 100).toFixed(2) : cellValue * 100}%`
    },
    /** 时长 */
    formatTime (row, column, cellValue) {
      return cellValue == null || cellValue === '' || cellValue === 0 ? '-' : YHTools.FormatTimeBySeconds(cellValue * 60, true)
    }

  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.vehicle-online-rate {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-light {
  .vehicle-online-rate {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
